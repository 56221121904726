<template>
  <div class="navigation_menu_wrap">
    <router-link to="/events">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'events')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiCalendarBlankOutline }}
        </v-icon>
        <span class="menu_link">Cборы</span>
      </div>
    </router-link>

    <router-link to="/clients">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'clients')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiAccount }}
        </v-icon>
        <span class="menu_link">Клиенты</span>
      </div>
    </router-link>

    <router-link to="/bonustransactions">
      <div
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'bonuspoints')"
        class="menu_block"
      >
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiStarFourPointsOutline }}
        </v-icon>
        <span class="menu_link">Бонусы</span>
      </div>
    </router-link>

    <router-link to="/coupons">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'events')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiTicketPercent }}
        </v-icon>
        <span class="menu_link">Купоны</span>
      </div>
    </router-link>

    <router-link to="/certs">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'certs')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiFileCertificate }}
        </v-icon>
        <span class="menu_link">Сертификаты</span>
      </div>
    </router-link>

    <router-link to="/club_player">
      <div
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'club_player')"
        class="menu_block"
      >
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiGift }}
        </v-icon>
        <span class="menu_link">Клубный игрок</span>
      </div>
    </router-link>

    <router-link to="/promocodes">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'promocodes')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiTicketPercent }}
        </v-icon>
        <span class="menu_link">Промокоды</span>
      </div>
    </router-link>

    <router-link to="/tax_deduction">
      <div
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'tax_deduction')"
        class="menu_block"
      >
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiFormSelect }}
        </v-icon>
        <span class="menu_link">Налоговый вычет</span>
      </div>
    </router-link>

    <router-link to="/acquiring">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'acquiring')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiCreditCardOutline }}
        </v-icon>
        <span class="menu_link">Эквайринг</span>
      </div>
    </router-link>

    <router-link to="/sales">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'sales')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiStarFourPointsOutline }}
        </v-icon>
        <span class="menu_link">Акции</span>
      </div>
    </router-link>

    <router-link to="/feedback">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'feedback')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiCommentQuote }}
        </v-icon>
        <span class="menu_link">Обратная связь</span>
      </div>
    </router-link>

    <router-link to="/qrcode_lockers">
      <div
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'qrcode_lockers')"
        class="menu_block"
      >
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiTable }}
        </v-icon>
        <span class="menu_link">Шкафчики ВДНХ</span>
      </div>
    </router-link>

    <router-link to="/reports">
      <div v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'reports')" class="menu_block">
        <v-icon color="white" size="60" class="menu_icon">
          {{ icons.mdiMicrosoftExcel }}
        </v-icon>
        <span class="menu_link">Отчеты</span>
      </div>
    </router-link>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccount,
  mdiAccountCogOutline,
  mdiAlphaTBoxOutline,
  mdiCalendarBlankOutline,
  mdiCommentQuote,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiFileCertificate,
  mdiFileOutline,
  mdiFormSelect,
  mdiGift,
  mdiHomeOutline,
  mdiMap,
  mdiMicrosoftExcel,
  mdiStarFourPointsOutline,
  mdiTable,
  mdiTicketPercent,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCalendarBlankOutline,
        mdiMap,
        mdiAccount,
        mdiStarFourPointsOutline,
        mdiTicketPercent,
        mdiFileCertificate,
        mdiGift,
        mdiCommentQuote,
        mdiMicrosoftExcel,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navigation_menu_wrap {
  .menu_block {
    display: inline-block;
    text-align: center;
    width: 200px;
    background: rgb(47, 125, 174);
    background: linear-gradient(180deg, rgba(47, 125, 174, 1) 0%, rgba(47, 87, 174, 1) 100%);
    border-radius: 10px;
    margin: 10px;
    padding: 20px;

    .menu_icon {
      display: block;
      margin: 0 auto;
    }

    .menu_link {
      display: inline;
      text-decoration: none;
      color: #fff;
    }
  }
}
</style>
